import React, { useEffect } from "react"
import Helmet from "react-helmet"
import styled from "styled-components"

import Header from "../components/Header"
import Footer from "../components/Footer"

import Theme from "../components/Theme"

import qs from "qs"

import emailConfirm from "../assets/email_confirmed.svg"
import fails from "../assets/fails.svg"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"

const Section = styled.div`
  margin-top: 64px;
  padding: 16px 8px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  justify-content: space-around;
  max-width: 1080px;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
  height: 100vh;
`

const Text = styled.div`
  flex: 1;
  strong {
    color: #fa824c;
    font-weight: normal;
  }
  margin-bottom: 16px;
`

const SectionTitle = styled.h2`
  margin-top: 0;
`

const Illustration = styled.div`
  flex: 1;
  text-align: center;
  padding-top: 8px;
`

const Failed = () => (
  <>
    <Text>
      <SectionTitle>Lien invalide, veuillez vérifier votre email.</SectionTitle>
      Le lien est invalide, si vous êtes certain d'avoir ouvert le lien fourni
      dans l'email de confirmation,{" "}
      <a href="mailto:contact@pharmille.com">contactez nous par email ici</a>.
    </Text>
    <Illustration>
      <img
        width="380px"
        src={fails}
        alt="Collaboration illustration"
        easing="ease-out-bounce"
      />
    </Illustration>
  </>
)

const Success = ({ type }) => {
  if (type === "USER") {
    window.location.replace("//scheduler.pharmille.com/confirmed")
    return
  }
  return (
    <>
      <Text>
        <SectionTitle>
          Votre email est <strong>confirmé</strong>
        </SectionTitle>
        Nous avons bien confirmé votre email. Nous vous informerons dès que
        possible pour profiter gratuitement d'une version test.
      </Text>
      <Illustration>
        <img
          width="380px"
          src={emailConfirm}
          alt="Collaboration illustration"
          easing="ease-out-bounce"
        />
      </Illustration>
    </>
  )
}

const CONFIRM_EMAIL_MUTATION = gql`
  mutation CONFIRM_EMAIL_MUTATION($token: String!) {
    confirm(token: $token) {
      type
      success
    }
  }
`

const Content = ({ error, loading, called, data }) => {
  if (!called || loading) return <CircularProgress />
  if (error) return <Failed />
  return <Success type={data.confirm.type} />
}

const ConfirmPage = ({ location }) => {
  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [checkToken, { error, loading, called, data }] = useMutation(
    CONFIRM_EMAIL_MUTATION
  )
  useEffect(() => {
    checkToken({ variables: { token } })
  }, [token, checkToken])
  return (
    <Theme>
      <Helmet>
        <meta charSet="utf-8" />
        <title defer={false}>Pharmille</title>
        <meta
          name="description"
          content="Pharmille simplifie la gestion du personnel de pharmacie."
        />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <Header bg="#08415C" />
      <Section>
        <Content error={error} loading={loading} called={called} data={data} />
      </Section>
      <Footer />
    </Theme>
  )
}

export default ConfirmPage
